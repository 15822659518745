import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  DynamicDataSheetGrid,
  keyColumn,
  textColumn,
} from 'react-datasheet-grid'
import { useApi } from '../../contexts/ApiProvider'
import 'react-virtualized-auto-sizer' // only needs to be imported once

import 'react-datasheet-grid/dist/style.css'
import './VariablesUI.css'
import selectColumn from '../selectColumn'
// import tagColumn from '../tagColumn'
import AutoSizer from 'react-virtualized-auto-sizer'
import {
  Button,
  ButtonGroup,
  InputGroup,
  Spinner,
  SpinnerSize,
} from '@blueprintjs/core'
import { VariableContextMenu } from './VariableContextMenu'
import SetQuestionDialog from './SetQuestionDialog'
import {
  flattenApiVariables,
  prepareApiVariables,
  SurveyContext,
} from '../../contexts/SurveyProvider'
import valuesColumn from '../valuesColumn'
import { useParams } from 'react-router-dom'
import { WindowContext } from '../../contexts/WindowProvider'
import ComputeVariableDialog from './ComputeVariableDialog'
import RecodeVariablesDialog from './RecodeVariablesDialog'
import FavoriteVariablesDialog from './FavoriteVariablesDialog'
import CenterVariablesDialog from './CenterVariablesDialog'
import DummyVariablesDialog from './DummyVariablesDialog'
import ImputeVariablesDialog from './ImputeVariablesDialog'
import { matchSorter } from 'match-sorter'
import { AppToaster } from '../toaster'
import QuestionReorderDialog from './QuestionReorderDialog'
import DeleteVariablesDialog from './DeleteVariablesDialog'
import sourceColumn from '../sourceColumn'
import CodeOpenEndDialog from './CodeOpenEndDialog'
import CodeOpenEndDialogV2 from './CodeOpenEndDialogV2'
import GenerateCodesDialog from './GenerateCodesDialog'

export const MULTI_VARIABLE_QUESTION_TYPES = [
  ['single-select-grid', 'Single Select Grid'],
  ['multi-select', 'Multi Select'],
  // ['multi-select-grid', 'Multi Select Grid'],
  ['number-multi', 'Number Multi'],
  // ['number-grid', 'Number Grid'],
  ['ranking', 'Ranking'],
  ['experiment', 'Experiment'],
  ['maxdiff', 'MaxDiff'],
]
export const QUESTION_TYPES = [
  ['text', 'Text'],
  ['single-select', 'Single Select'],
  // ['single-select-grid', 'Single Select Grid'],
  ['multi-select', 'Multi Select'],
  // ['multi-select-grid', 'Multi Select Grid'],
  ['number', 'Number'],
  // ['number-multi', 'Number Multi'],
  // ['number-grid', 'Number Grid'],
  ['date', 'Date'],
  ['ranking', 'Ranking'],
  ['experiment', 'Experiment'],
  ['maxdiff', 'MaxDiff'],
  ['segmentation', 'Segmentation'],
]
export const VARIABLE_TYPES = [
  ['categorical', 'Categorical'],
  ['text', 'Text'],
  ['number', 'Number'],
  ['date', 'Date'],
  ['ranking', 'Ranking'],
  ['experiment', 'Experiment'],
]

export const NUMERIC_VARIABLE_TYPES = [
  'categorical',
  'number',
  'ranking',
  'experiment',
]

const ALL_TAGS = [
  ['is_hidden', 'eye-off'],
  ['is_filter', 'filter'],
  ['is_weight', 'database'],
]

export const VariableContextMenuContext = createContext()

const VariablesUI = () => {
  const [loading, setLoading] = useState(false)
  const {
    surveyDialogOpen,
    setSurveyDialogOpen,
    variables,
    prevVariables,
    setVariables,
    setPrevVariables,
  } = useContext(SurveyContext)
  const { currentSurvey } = useContext(WindowContext)
  const { surveyId } = useParams()
  const api = useApi()
  const gridRef = useRef()
  const searchRef = useRef()

  const handleSearch = () => {
    const search = searchRef.current.value
    if (!search) {
      AppToaster.show({
        message: `Please enter a search term`,
        intent: 'warning',
        timeout: 1500,
        isCloseButtonShown: false,
      })
      return
    }
    const matches = matchSorter(variables, search, {
      keys: ['name', 'question', 'label', 'id'],
    })
    if (!matches.length) {
      AppToaster.show({
        message: `No matches found for "${search}"`,
        intent: 'warning',
        timeout: 1500,
        isCloseButtonShown: false,
      })
      return
    }
    const firstMatch = matches[0]
    const firstMatchIndex = variables.findIndex(v => v.id === firstMatch.id)
    gridRef.current.setSelection({
      min: { col: 0, row: firstMatchIndex },
      max: { col: 6, row: firstMatchIndex },
    })
  }

  const columns = useMemo(
    () => [
      {
        ...keyColumn('name', textColumn),
        title: 'Name',
        width: 0.5,
        minWidth: 150,
      },
      {
        ...keyColumn('label', textColumn),
        title: 'Label',
        width: 3,
        // renderWhenScrolling: false,
      },
      {
        title: 'Variable Type',
        width: 0.4,
        // renderWhenScrolling: false,
        minWidth: 120,
        maxWidth: 120,
        ...keyColumn(
          'variable_type',
          selectColumn({
            choices: VARIABLE_TYPES.map(([value, label]) => ({ value, label })),
          })
        ),
      },
      {
        title: 'Values',
        width: 0.25,
        // renderWhenScrolling: false,
        minWidth: 50,
        ...keyColumn('value_labels', valuesColumn({ disabled: false })),
      },
      {
        ...keyColumn('question', textColumn),
        title: 'Question',
        disabled: true,
        // renderWhenScrolling: false,
      },
      {
        title: 'Question Type',
        width: 0.5,
        // renderWhenScrolling: false,
        minWidth: 150,
        ...keyColumn(
          'question_type',
          selectColumn({
            disabled: true,
            choices: QUESTION_TYPES.map(([value, label]) => ({ value, label })),
          })
        ),
      },
      {
        title: 'Calcs',
        width: 0.25,
        // renderWhenScrolling: false,
        disabled: true,
        minWidth: 60,
        ...keyColumn('formulas', sourceColumn({ disabled: true })),
      },
    ],
    []
  )
  const updatedRowIds = useMemo(() => new Set(), [])
  const cancel = () => {
    setVariables(prevVariables)
    updatedRowIds.clear()
  }

  const getVariable = (id, variables) =>
    variables.find(variable => variable.id === id)

  const commit = async () => {
    let modifications = [...updatedRowIds].map(id => {
      return Object.fromEntries(
        Object.entries(getVariable(id, variables)).flatMap(([key, value]) =>
          key === 'id' || getVariable(id, prevVariables)[key] !== value
            ? [[key, value]]
            : []
        )
      )
    })
    modifications = prepareApiVariables(modifications)

    setLoading(true)
    const response = await api.put(
      `/survey/${surveyId}/variables`,
      modifications
    )

    if (response.ok) {
      updatedRowIds.clear()
      const newVariables = flattenApiVariables(response.body)
      setVariables(newVariables)
      setPrevVariables(newVariables)
    } else {
      let messages = response?.body?.messages?.json
      if (messages) {
        messages = Object.values(messages)
          .map(dict => Object.values(dict).join(', '))
          .join(', ')
        alert(messages)
      }
      console.log({ response })
    }
    setLoading(false)
  }

  const openQuestionDialog = useCallback(() => {
    gridRef.current.setActiveCell(null)
    setSurveyDialogOpen('SetQuestionDialog')
  }, [setSurveyDialogOpen])

  const openDeleteVariablesDialog = useCallback(() => {
    gridRef.current.setActiveCell(null)
    setSurveyDialogOpen(
      `DeleteVariablesDialog-${variables.map(v => v.id).join(',')}`
    )
  }, [setSurveyDialogOpen, variables])

  return (
    <>
      {variables === undefined || currentSurvey === undefined ? (
        <p>loading...</p>
      ) : variables === null ? (
        <p>Couldn't retrieve variables</p>
      ) : variables.length === 0 ? (
        'No variables found.'
      ) : (
        <>
          <QuestionReorderDialog
            isOpen={surveyDialogOpen === 'QuestionReorderDialog'}
            onClose={() => setSurveyDialogOpen()}
          />
          <DeleteVariablesDialog
            isOpen={surveyDialogOpen?.startsWith('DeleteVariablesDialog')}
            onClose={() => setSurveyDialogOpen()}
          />
          <SetQuestionDialog
            isOpen={surveyDialogOpen === 'SetQuestionDialog'}
            onClose={() => setSurveyDialogOpen()}
          />
          <ComputeVariableDialog
            isOpen={surveyDialogOpen === 'CreateVariableDialog-Compute'}
            onClose={() => setSurveyDialogOpen()}
          />
          <RecodeVariablesDialog
            isOpen={surveyDialogOpen === 'CreateVariableDialog-Recode'}
            onClose={() => setSurveyDialogOpen()}
          />
          <FavoriteVariablesDialog
            isOpen={surveyDialogOpen === 'CreateVariableDialog-Favorite'}
            onClose={() => setSurveyDialogOpen()}
          />
          <CenterVariablesDialog
            isOpen={surveyDialogOpen === 'CreateVariableDialog-Center'}
            onClose={() => setSurveyDialogOpen()}
          />
          <DummyVariablesDialog
            isOpen={surveyDialogOpen === 'CreateVariableDialog-Dummy'}
            onClose={() => setSurveyDialogOpen()}
          />
          <ImputeVariablesDialog
            isOpen={surveyDialogOpen === 'CreateVariableDialog-Impute'}
            onClose={() => setSurveyDialogOpen()}
          />
          <CodeOpenEndDialog
            isOpen={surveyDialogOpen === 'CreateVariableDialog-Code Open Ends'}
            onClose={() => setSurveyDialogOpen()}
          />
          <CodeOpenEndDialogV2
            isOpen={surveyDialogOpen === 'CreateVariableDialog-Code Open Ends V2'}
            onClose={() => setSurveyDialogOpen()}
          />
          <GenerateCodesDialog
            isOpen={surveyDialogOpen === 'CreateVariableDialog-Generate Codes'}
            onClose={() => setSurveyDialogOpen()}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: 2,
            }}
          >
            <form
              onSubmit={e => {
                e.preventDefault()
                handleSearch()
              }}
            >
              <InputGroup
                type="search"
                placeholder="Search..."
                // leftIcon="search"
                small
                // rightElement={
                //   <Button icon="cross" minimal={true} intent="danger" />
                // }
                // value={search}
                // onChange={e => setSearch(e.target.value)}
                inputRef={searchRef}
                rightElement={
                  <Button
                    type="submit"
                    icon="search"
                    style={{ borderRadius: '50%' }}
                    minimal
                  />
                }
              />
            </form>
            <ButtonGroup style={{ float: 'right' }}>
              <Button
                onClick={cancel}
                disabled={updatedRowIds.size === 0}
                intent="danger"
                minimal
                rightIcon="undo"
                small
              >
                Cancel
              </Button>
              <Button
                loading={loading}
                onClick={commit}
                disabled={updatedRowIds.size === 0}
                intent="success"
                rightIcon="floppy-disk"
                small
              >
                Commit
              </Button>
            </ButtonGroup>
          </div>
          <VariableContextMenuContext.Provider
            value={{ openQuestionDialog, openDeleteVariablesDialog }}
          >
            <AutoSizer>
              {({ height, width }) => (
                <>
                  {loading ? (
                    <Spinner
                      size={SpinnerSize.LARGE}
                      style={{
                        position: 'absolute',
                        left: '50vw',
                        top: '40vh',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  ) : null}
                  <DynamicDataSheetGrid
                    ref={gridRef}
                    // onBlur={({ data }) => console.log({ data })}
                    // onSelectionChange={data => console.log({ data })}
                    className="variable-table"
                    value={variables}
                    contextMenuComponent={VariableContextMenu}
                    // lockRows={true}
                    // disableExpandSelection
                    disableContextMenu={false}
                    // onChange={setVariables}
                    columns={columns}
                    addRowsComponent={() => null}
                    height={height - 28}
                    style={{
                      height: height - 28,
                      width,
                      ...(loading ? { opacity: 0.5, filter: 'blur(1px)' } : {}),
                    }}
                    rowHeight={22}
                    rowKey="id"
                    rowClassName={({ rowData }) => {
                      const classNames = []
                      if (updatedRowIds.has(rowData.id)) {
                        classNames.push('row-updated')
                      }
                      // if (rowData.is_source) {
                      //   classNames.push('row-source')
                      // }
                      return classNames.join(' ')
                    }}
                    onChange={(newValue, operations) => {
                      for (const operation of operations) {
                        if (operation.type === 'UPDATE') {
                          newValue
                            .slice(operation.fromRowIndex, operation.toRowIndex)
                            .forEach(newVar => {
                              const prev = prevVariables
                                ? prevVariables.find(
                                    old => old.id === newVar.id
                                  )
                                : undefined
                              const noChange =
                                JSON.stringify(prev) === JSON.stringify(newVar)
                              if (noChange) {
                                updatedRowIds.delete(newVar.id)
                              } else {
                                updatedRowIds.add(newVar.id)
                              }
                            })
                        }
                      }
                      setVariables(newValue)
                    }}
                  />
                </>
              )}
            </AutoSizer>
          </VariableContextMenuContext.Provider>
        </>
      )}
    </>
  )
}

export default VariablesUI
