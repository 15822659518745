import {
    useContext,
    useState,
} from 'react'
import { useApi } from '../../contexts/ApiProvider'
import { SurveyContext } from '../../contexts/SurveyProvider'
import variableOrQuestionToString from '../../utils/variableOrQuestionToString'
import highlightText from '../../utils/highlightText'
import { useParams } from 'react-router-dom'
import { AppToaster } from '../toaster'
import { errorObjectToString } from '../../ApiClient'
import {
    Alert,
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    FormGroup,
    MenuItem,
} from '@blueprintjs/core'
import { Select2 } from '@blueprintjs/select'

export default function GenerateCodesDialog({ isOpen, onClose }) {
    const { variables, setSurveyDialogOpen } = useContext(SurveyContext)
    const textVariables = variables.filter(v => v.variable_type === 'text')
    const [selectedVariable, setSelectedVariable] = useState()
    const [confirmAlertOpen, setConfirmAlertOpen] = useState(false)
    const { surveyId } = useParams()

    const filterVariable = (query, variable, _index, exactMatch) => {
        const normalizedName = variable.name.toLowerCase()
        const normalizedQuery = query.toLowerCase()
    
        if (exactMatch) {
          return normalizedQuery === normalizedName
        } else {
          return normalizedName.indexOf(normalizedQuery) >= 0
        }
    }

    const variableRenderer = (
        variable,
        { handleClick, handleFocus, modifiers, query }
      ) => {
        if (!modifiers.matchesPredicate) {
          return null
        }
        const text = variableOrQuestionToString(variable)
        return (
          <MenuItem
            selected={modifiers.active}
            shouldDismissPopover={true}
            disabled={modifiers.disabled}
            label={variable.type}
            key={variable.id}
            onClick={handleClick}
            onFocus={handleFocus}
            text={highlightText(text, query)}
          />
        )
      }

      const api = useApi()

      const handleRequest = async () => {
        const request = {
            variable_id: selectedVariable?.id,
            survey_id: surveyId,
        }
        const response = await api.post(
            `/surveys/${surveyId}/variables/generate_codeframe`,
            request
        )
        setConfirmAlertOpen(false)
        setSurveyDialogOpen()
        if (response.ok) {
            AppToaster.show({
              message:
                "Generating codes. You'll receive an email with the results when completed.",
              intent: 'success',
              icon: 'tick-circle',
            })
          } else {
            const message = errorObjectToString(response.body.messages.json)
            AppToaster.show({ message, intent: 'danger', icon: 'error' })
          }
      }

    return (
        <Dialog
            id="generate-codes-variable"
            icon="code"
            title="Generate Codes"
            isOpen={isOpen}
            onClose={onClose}
            style={{ width: '80vw', maxWidth: '800px' }}
        >
            <DialogBody id='generate-codes-variable-panel'>
                <FormGroup
                    label="Open End Variable - required"
                    labelFor="generate-codes-variable"
                >
                    <Select2
                        id="variable"
                        items={textVariables}
                        itemPredicate={filterVariable}
                        itemRenderer={variableRenderer}
                        onItemSelect={setSelectedVariable}
                        selectedItem={selectedVariable}
                        noResults={
                        <MenuItem
                            disabled={true}
                            text="No results."
                            roleStructure="listoption"
                        />
                        }
                    >
                        <Button
                            text={
                                selectedVariable
                                ? variableOrQuestionToString(selectedVariable)
                                : 'Select a variable'
                            }
                            rightIcon="double-caret-vertical"
                            icon="manually-entered-data"
                            placeholder="Select a variable"
                            style={{ backgroundColor: 'white' }}
                        />
                    </Select2>
                </FormGroup>
                <div>
                    <Alert
                        isOpen={confirmAlertOpen}
                        loading={false}
                        cancelButtonText="Cancel"
                        confirmButtonText="Generate Codes"
                        onConfirm={handleRequest}
                        onCancel={() => setConfirmAlertOpen(false)}
                        cancel
                        intent="success"
                    >
                        {
                        <p>
                            You are about to enqueue a code generation job. Only 3,000 open-ended text
                            will be used to generate codes. You may view progress on the Tasks page.
                        </p>
                        }
                    </Alert>
                </div>
                <DialogFooter
                    actions={
                        <Button
                            text="Generate Codes"
                            intent="success"
                            disabled={!selectedVariable}
                            onClick={() => setConfirmAlertOpen(true)}
                        />
                    }
                />
            </DialogBody>
        </Dialog>
    )
}