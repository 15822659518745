import {
    useContext,
    useMemo,
    useState,
  } from 'react'
  import { WindowContext } from '../../contexts/WindowProvider'
  import { SurveyContext } from '../../contexts/SurveyProvider'
  import { recodeTypes } from '../../pages/VariablesPage'
  import AutoSizer from 'react-virtualized-auto-sizer'
  import {
    Button,
    Classes,
    DialogBody,
    DialogStep,
    FormGroup,
    Icon,
    Menu as BpMenu,
    MenuDivider,
    MenuItem,
    MultistepDialog,
    TextArea,
    Alert,
    InputGroup,
    Checkbox,
  } from '@blueprintjs/core'
  import variableOrQuestionToString from '../../utils/variableOrQuestionToString'
  import { Popover2 } from '@blueprintjs/popover2'
  import { Select2 } from '@blueprintjs/select'
  import highlightText from '../../utils/highlightText'
  import './CodeOpenEndDialog.css'
  import { useApi } from '../../contexts/ApiProvider'
  import { useParams } from 'react-router-dom'
  import { AppToaster } from '../toaster'
  import { errorObjectToString } from '../../ApiClient'
  import { useUser } from '../../contexts/UserProvider'
  
  export default function CodeOpenEndDialogV2({ isOpen, onClose }) {
    const { setLoading } = useContext(WindowContext)
    const {
      user: { notifications },
    } = useUser()
    const [taskId, setTaskId] = useState()
    const [maxComments, setmaxComments] = useState()
    const [startRespondent, setstartRespondent] = useState()
    const [codeAllTheThings, setCodeAllTheThings] = useState(false)
    const [includeLogprobs, setIncludeLogprobs] = useState(false)
    const [brandCoding, setBrandCoding] = useState(false)
    const api = useApi()
    const { surveyId } = useParams()
    const { surveyDialogOpen, setSurveyDialogOpen, variables, nRespondents } =
      useContext(SurveyContext)
    const type = surveyDialogOpen
      ? recodeTypes.find(r => r?.name === surveyDialogOpen.split('-')[1])
      : undefined

    const [confirmAlertOpen, setConfirmAlertOpen] = useState(false)
  
    const [numRespondents, setNumRespondents] = useState(2)
    const [selectedVariable, setSelectedVariable] = useState()
    const [selectedRespondent, setSelectedRespondent] = useState()
    const [codes, setCodes] = useState()
    const [prompt, setPrompt] = useState('')
    const [prevSubmission, setPrevSubmission] = useState()
    const textVariables = variables.filter(v => v.variable_type === 'text')
    const intVariables = [{name: "No Respondent ID", id: null}].concat(
        variables.filter(v => v.variable_type === 'number' || v.variable_type === 'text')
    )
    const filterVariable = (query, variable, _index, exactMatch) => {
      const normalizedName = variable.name.toLowerCase()
      const normalizedQuery = query.toLowerCase()
  
      if (exactMatch) {
        return normalizedQuery === normalizedName
      } else {
        return normalizedName.indexOf(normalizedQuery) >= 0
      }
    }
    const variableRenderer = (
      variable,
      { handleClick, handleFocus, modifiers, query }
    ) => {
      if (!modifiers.matchesPredicate) {
        return null
      }
      const text = variableOrQuestionToString(variable)
      return (
        <MenuItem
          selected={modifiers.active}
          shouldDismissPopover={true}
          disabled={modifiers.disabled}
          label={variable.type}
          key={variable.id}
          onClick={handleClick}
          onFocus={handleFocus}
          text={highlightText(text, query)}
        />
      )
    }

    const handleBrandCheckboxChange = () => {
      setBrandCoding(prevState => !prevState)
    }

    const handleCheckboxChange = () => {
        setCodeAllTheThings(prevState => !prevState)
    }

    const handleLogprobChange = () => {
      setIncludeLogprobs(prevState => !prevState)
    }

    const errors = useMemo(() => {
      const request = {
        variable: selectedVariable,
        prompt,
        codes: codes
          ? codes
              .split('\n')
              .map(code => code.trim())
              .filter(code => code.length > 0)
          : [],
        num_respondents: numRespondents,
        start_respondent: startRespondent,
        code_all_the_things: codeAllTheThings,
        include_logprobs: includeLogprobs,
        brand_coding: brandCoding,
        survey_id: surveyId,
      }
      const errors = {}
      if (request.variable === undefined) {
        errors.variable = 'No variable selected'
      }
      if (!request.codes || request.codes.length < 2) {
        errors.codes = 'Not enough codes'
      }
      if (request.numRespondents < 1) {
        errors.numRespondents = 'Not enough respondents'
      } else if (
        request.numRespondents > nRespondents ||
        request.startRespondent + request.numRespondents > nRespondents
      ) {
        errors.numRespondents = `Too many respondents. Only ${nRespondents} in survey.`
      }
      return errors
    }, [
      codes,
      prompt,
      nRespondents,
      numRespondents,
      selectedVariable,
      startRespondent,
      surveyId,
    ])
    function getOpenEndRequest() {
        const result = {
          variable: selectedVariable?.id,
          prompt,
          codeAllTheThings: codeAllTheThings,
          includeLogprobs: includeLogprobs,
          brandCoding: brandCoding,
          codes: codes
            ? codes
                .split('\n')
                .map(code => code.trim())
                .filter(code => code.length > 0)
            : [],
          survey_id: surveyId,
          // threshold: cutoff,
        };

        if (selectedRespondent && selectedRespondent.id) {
          result.respondent = selectedRespondent.id;
        }

        if (maxComments) {
          result.maxComments = maxComments;
        }

        if (startRespondent) {
          result.startRespondent = startRespondent
        }
      
        return result;
      }

    const hasChanged =
      prevSubmission === undefined ||
      JSON.stringify(getOpenEndRequest()) !== prevSubmission
  
    const handleFullRunRequest = async () => {
      const request = getOpenEndRequest(false)
      const response = await api.post(
        `/survey/${surveyId}/variables/code_oes/queue/full_run_v2`,
        request
      )
      setConfirmAlertOpen(false)
      setSurveyDialogOpen()
      if (response.ok) {
        AppToaster.show({
          message:
            "Open End Coding Requested. You'll receive an email with the results when completed.",
          intent: 'success',
          icon: 'tick-circle',
        })
      } else {
        const message = errorObjectToString(response.body.messages.json)
        AppToaster.show({ message, intent: 'danger', icon: 'error' })
      }
      setLoading(false)
    }
        
    return (
      <>
        <AutoSizer>
          {({ width, height }) => {
            return (
              <MultistepDialog
                transitionDuration={surveyDialogOpen ? 0 : 300}
                finalButtonProps={{
                  text: 'Code Open End',
                  intent: 'success',
                  onClick: () => {
                    setConfirmAlertOpen(true)
                  },
                }}
                style={{ width: width - 100, height: height - 100 }}
                onClose={onClose}
                title={
                  <Popover2
                    minimal
                    placement="bottom-start"
                    content={
                      <BpMenu>
                        {recodeTypes.map((recode, i) =>
                          recode !== null ? (
                            <MenuItem
                              key={i}
                              icon={recode.icon}
                              text={recode.name}
                              labelElement={
                                <Icon
                                  icon={type === recode ? 'small-tick' : 'blank'}
                                />
                              }
                              onClick={() =>
                                setSurveyDialogOpen(
                                  `CreateVariableDialog-${recode.name}`
                                )
                              }
                            />
                          ) : (
                            <MenuDivider key={i} />
                          )
                        )}
                      </BpMenu>
                    }
                  >
                    <Button
                      minimal
                      text={type?.name}
                      rightIcon="caret-down"
                      icon={type?.icon}
                    />
                  </Popover2>
                }
                isOpen={isOpen}
                isCloseButtonShown={true}
              >
                <DialogStep
                  id="prompt"
                  title="Prompt"
                  panel={
                    <DialogBody id="prompt-panel">
                      <FormGroup
                        label='Prompt'
                        labelFor="prompt-input"
                      >
                        <TextArea
                          id='prompt-input'
                          rows={10}
                          className={Classes.INPUT}
                          small
                          fill
                          onChange={ev => setPrompt(ev.target.value)}
                          value={prompt}
                        />
                      </FormGroup>
                      <FormGroup
                        helperText={
                          <div>
                            Check this box if you are only interested in detecting brand names 
                            that are present in open-ended text.
                          </div>
                        }
                      >
                        <Checkbox
                          checked={brandCoding}
                          onChange={handleBrandCheckboxChange}
                        >
                          Brand detection
                        </Checkbox>
                      </FormGroup>
                    </DialogBody>
                  }
                />
                <DialogStep
                  id="variable"
                  title="Variables"
                  nextButtonProps={selectedVariable ? {} : { disabled: true }}
                  panel={
                    <DialogBody id="variable-panel">
                      <FormGroup
                        label="Open End Variable to Code - required"
                        labelFor="variable"
                      >
                        <Select2
                          id="variable"
                          items={textVariables}
                          itemPredicate={filterVariable}
                          itemRenderer={variableRenderer}
                          onItemSelect={setSelectedVariable}
                          selectedItem={selectedVariable}
                          noResults={
                            <MenuItem
                              disabled={true}
                              text="No results."
                              roleStructure="listoption"
                            />
                          }
                        >
                          <Button
                            text={
                              selectedVariable
                                ? variableOrQuestionToString(selectedVariable)
                                : 'Select a variable'
                            }
                            rightIcon="double-caret-vertical"
                            icon="manually-entered-data"
                            placeholder="Select a variable"
                            style={{ backgroundColor: 'white' }}
                          />
                        </Select2>
                      </FormGroup>
                      <FormGroup
                        label="Variable that maps to respondent's ID. HIGHLY RECOMMENDED. Must be unique."
                        labelFor="respondent"
                      >
                        <Select2
                          id="respondent"
                          items={intVariables}
                          itemPredicate={filterVariable}
                          itemRenderer={variableRenderer}
                          onItemSelect={setSelectedRespondent}
                          selectedItem={selectedRespondent}
                          noResults={
                            <MenuItem
                              disabled={true}
                              text="No results."
                              roleStructure="listoption"
                            />
                          }
                        >
                          <Button
                            text={
                              selectedRespondent
                                ? variableOrQuestionToString(selectedRespondent)
                                : 'Select a variable'
                            }
                            rightIcon="double-caret-vertical"
                            icon="manually-entered-data"
                            placeholder="Select a variable"
                            style={{ backgroundColor: 'white' }}
                          />
                        </Select2>
                      </FormGroup>
                      <FormGroup
                        label="Maximum items to code. If left blank, all items will be coded."
                      >
                        <InputGroup
                          id="maxComments"
                          type="number"
                          value={maxComments}
                          onChange={ev => setmaxComments(parseInt(ev.target.value))}
                        />
                      </FormGroup>
                      <FormGroup
                        label="Respondent to start with. If left blank, the first respondent will be used."
                      >
                        <InputGroup
                          id="startRespondent"
                          type="number"
                          value={startRespondent}
                          onChange={ev => setstartRespondent(parseInt(ev.target.value))}
                        />
                      </FormGroup>
                      <FormGroup
                        helperText={<div>By default, certain comments/verbatims 
                          (nan, n/a, na, no answer, don't know, dk, none, nothing, all, everything)
                          will be assigned to categories "No answer", "None", "All", and "Don't know" without
                          being sent to openai, because openai tends to have trouble applying codes to these open-ends. 
                          If your dataset includes many "nan", leave this box unchecked to avoid unnecessary calls to openai.
                          However, if, for some reason, you would like the ai to try to process these comments, check this box.</div>}
                      >
                        <Checkbox
                          checked={codeAllTheThings}
                          onChange={handleCheckboxChange}
                        >
                          Skip manual coding.
                        </Checkbox>
                      </FormGroup>
                    </DialogBody>
                  }
                />
                <DialogStep
                  id="codes"
                  title="Codes"
                  nextButtonProps={{
                    onMouseDown: ev => {
                      if (hasChanged) {
                        setTaskId()
                        if (Object.keys(errors).length > 0) {
                          ev.preventDefault()
                          ev.stopPropagation()
                          return
                        }
                      }
                    },
                    disabled: Object.keys(errors).length > 0,
                    text: "Next",
                  }}
                  panel={
                    <DialogBody id="options-panel" className="options-panel">
                      <FormGroup
                        label="Codes"
                        labelFor="codes-input"
                        subLabel="Enter one code per line. Use a consistent format for all codes."
                      >
                        <div>
                          {brandCoding && (
                            <div style={{ color: 'red' }}>
                              Do not include any code that is not a brand name.<br/>
                              The final results will include the categories<br/>
                              "Some other brand that is not in the list above"
                              and "Not relevant"
                            </div>
                          )}
                        </div>
                        <TextArea
                          id="codes-input"
                          growVertically
                          rows={17}
                          className={Classes.INPUT}
                          style={{ textWrap: 'nowrap' }}
                          small
                          fill
                          onChange={ev => setCodes(ev.target.value)}
                          value={codes}
                        />
                      </FormGroup>
                      {!brandCoding && ( // don't allow logprobs for brand coding at this time
                        <FormGroup
                          helperText={<div>
                            While in beta, no more than 5k items will be coded if this box is checked.
                            <br />
                            An excel sheet will be generated with the confidence scores 
                            for each cell.
                            <br />
                            Confidence scores will not be generated for manually-coded rows.
                            <br />
                            Including confidence scores will increase processing time.
                          </div>}
                        >
                          <Checkbox
                            checked={includeLogprobs}
                            onChange={handleLogprobChange}
                          >
                            Include confidence scores - Beta
                          </Checkbox>
                        </FormGroup>
                      )}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: 6,
                        }}
                      >
                      </div>
                      <div>
                        <Alert
                          isOpen={confirmAlertOpen}
                          loading={false}
                          cancelButtonText="Cancel"
                          confirmButtonText="Code Survey"
                          onConfirm={handleFullRunRequest}
                          onCancel={() => setConfirmAlertOpen(false)}
                          cancel
                          intent="success"
                        >
                          {includeLogprobs ?
                            <p>
                              You are about to enqueue a coding task. 
                              Check the progress of your task on the "Tasks" (/tasks) page. 
                              NO MORE THAN 5,000 ITEMS WILL BE CODED.
                            </p>
                            :
                            <p>
                              You are about to enqueue a coding task. 
                              Check the progress of your task on the "Tasks" (/tasks) page. 
                              NO MORE THAN 100,000 ITEMS WILL BE CODED.
                            </p>
                          }
                        </Alert>
                      </div>
                    </DialogBody>
                  }
                />
              </MultistepDialog>
            )
          }}
        </AutoSizer>
      </>
    )
  }
  