import React, { useContext } from 'react'
import { Popover2 } from '@blueprintjs/popover2'
import VariablesUI from '../components/variables/VariablesUI'
import { WindowContext } from '../contexts/WindowProvider'
import { useDocumentTitle } from '../utils/hooks'
import Upload from '../components/Upload'
import ToolPage from '../components/ToolPage'
import Menu from '../components/Menu'
import LoadingSpinner from '../components/LoadingSpinner'
import {
  MenuItem,
  Menu as BpMenu,
  Icon,
  Button,
  MenuDivider,
} from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import { SurveyContext } from '../contexts/SurveyProvider'

export const recodeTypes = [
  { name: 'Compute', icon: 'calculator' },
  { name: 'Recode', icon: 'swap-horizontal' },
  null,
  { name: 'Favorite', icon: 'heart' },
  { name: 'Center', icon: 'alignment-vertical-center' },
  { name: 'Dummy', icon: 'fork' },
  null,
  { name: 'Impute', icon: 'tree' },
  null,
  { name: 'Code Open Ends', icon: 'clean' },
  { name: 'Code Open Ends V2', icon: 'rocket-slant' },
  { name: 'Generate Codes', icon: 'asterisk' },
]

export default function VariablesPage() {
  const { currentSurvey } = useContext(WindowContext)
  const { setSurveyDialogOpen } = useContext(SurveyContext)
  const pageName = `${currentSurvey ? 'Variables' : 'Loading - Variables'}`
  useDocumentTitle(pageName)
  return (
    <ToolPage
      menu={
        <Menu pageName={pageName}>
          <Popover2
            minimal
            placement="bottom-start"
            content={
              <BpMenu>
                {recodeTypes.map((recode, i) =>
                  recode !== null ? (
                    <MenuItem
                      key={i}
                      icon={recode.icon}
                      text={recode.name}
                      onClick={() =>
                        setSurveyDialogOpen(
                          `CreateVariableDialog-${recode.name}`
                        )
                      }
                    />
                  ) : (
                    <MenuDivider key={i} />
                  )
                )}
              </BpMenu>
            }
          >
            <Button text={'Create'} />
          </Popover2>
          <Popover2
            minimal
            placement="bottom-start"
            content={
              <BpMenu>
                <MenuItem
                  icon={'trash'}
                  text={'Delete Variables'}
                  onClick={() => setSurveyDialogOpen('DeleteVariablesDialog')}
                />
                <MenuItem
                  icon={'swap-vertical'}
                  text={'Reorder Questions'}
                  onClick={() => setSurveyDialogOpen('QuestionReorderDialog')}
                />
              </BpMenu>
            }
          >
            <Button text={'Edit'} />
          </Popover2>
          <Popover2
            minimal
            placement="bottom-start"
            content={
              <BpMenu>
                <MenuItem
                  text="Segmentation Explorer"
                  icon="inherited-group"
                  onClick={() =>
                    setSurveyDialogOpen('SegmentationExplorerDialog')
                  }
                />
              </BpMenu>
            }
          >
            <Button text="Window" />
          </Popover2>
          <Popover2
            minimal
            placement="bottom-start"
            content={
              <BpMenu>
                <MenuItem
                  text={
                    <Link to="/help/variables" target="_blank">
                      Variables Help Video
                      <Icon className="new-page-icon" icon="share" size={12} />
                    </Link>
                  }
                />
              </BpMenu>
            }
          >
            <Button text={'Help'} />
          </Popover2>
        </Menu>
      }
      tool={
        currentSurvey ? (
          currentSurvey.has_data ? (
            <VariablesUI />
          ) : (
            <Upload />
          )
        ) : (
          <LoadingSpinner />
        )
      }
    />
  )
}
