import { useEffect, useState } from 'react'
import { useApi } from '../contexts/ApiProvider'
import { AppToaster } from './toaster'
import { Link } from 'react-router-dom'
import { errorObjectToString } from '../ApiClient'
import { Alert, Button, HTMLTable } from '@blueprintjs/core'

export default function Tasks() {
  const api = useApi()
  const [tasks, setTasks] = useState()
  const [cancelAlertOpen, setCancelAlertOpen] = useState(false)
  const [cancelTaskId, setCancelTaskId] = useState()

  const cancelTask = async (task_id) => {
    setCancelAlertOpen(false)
    const response = await api.post("/tasks/" + task_id + "/cancel")
    if (response.ok) {
      AppToaster.show({
        message: 'Task canceled',
        intent: 'success',
        icon: 'tick',
      })
    } else {
      const message = response.body.error
      AppToaster.show({
        message,
        intent: 'danger',
        icon: 'error',
      })
    }
  }

  const handleCancel = (taskId) => {
    setCancelAlertOpen(true)
    setCancelTaskId(taskId)
  }

  useEffect(() => {
    ;(async () => {
      const response = await api.get('/tasks')
      if (response.ok) {
        const tasks = response.body
        setTasks(tasks)
      } else {
        const message = errorObjectToString(response.body.messages.json)
        AppToaster.show({
          message,
          intent: 'danger',
          icon: 'error',
        })
      }
    })()
  }, [api])
  return (
    <div>
      <HTMLTable>
        <tbody>
          <tr>
            <th>Task ID</th>
            <th>Name</th>
            <th>Description</th>
            <th>Survey ID</th>
            <th>Progress</th>
            <th>Created by User ID</th>
            <th>Status</th>
            <th>Created at (UTC)</th>
            <th>Started at (UTC)</th>
            <th>Last Updated at (UTC)</th>
            <th>Canceled at (UTC)</th>
            <th>Canceled by User ID</th>
            <th>Error Messages</th>
            <th>Actions</th>
          </tr>
          {tasks ? (
            tasks.map(task => {
              let canceledAt;
              let startedAt;
              let lastUpdated;
              let createdAt;

              if (task?.canceled_at) {
                canceledAt = new Date(task?.canceled_at?.replace("T", " ").replace("Z", '')).toLocaleString();
              } else {
                canceledAt = '';
              }

              if (task?.created_at) {
                createdAt = new Date(task?.created_at?.replace("T", " ").replace("Z", '')).toLocaleString();
              } else {
                createdAt = '';
              }

              if (task?.last_updated) {
                lastUpdated = new Date(task?.last_updated?.replace("T", " ").replace("Z", '')).toLocaleString();
              } else {
                lastUpdated = '';
              }

              if (task?.started_at) {
                startedAt = new Date(task?.started_at?.replace("T", " ").replace("Z", '')).toLocaleString();
              } else {
                startedAt = '';
              }

              let deducedStatus = null;
              if (!task.status) {
                const secs_since_last_update = task.last_updated
                  ? new Date().getTime() -
                    new Date(Date.parse(task.last_updated)).getTime()
                  : undefined
                const has_likely_failed =
                  secs_since_last_update === undefined ||
                  secs_since_last_update > 60 * 30
                deducedStatus = task?.started_at
                  ? task.complete
                    ? task.succeeded
                      ? 'success ✅'
                      : 'failed ❌'
                    : 'running 🏃'
                  : !has_likely_failed
                  ? 'queued 🕒'
                  : 'timeout ❌'
              }
              return (
                <tr key={task.id}>
                  <td>{task.id}</td>
                  <td>{task.name}</td>
                  <td width='10%'>{task.description}</td>
                  <td><Link to={`/survey/${task.survey_id}`}>{task.survey_id}</Link></td>
                  <td>{task.progress}%</td>
                  <td>{task.user_id}</td>
                  <td>{deducedStatus || task.status}</td>
                  <td>{createdAt}</td>
                  <td>{startedAt}</td>
                  <td>{lastUpdated}</td>
                  <td>{canceledAt}</td>
                  <td>{task.canceled_by_user_id}</td>
                  <td width='10%'>{task.error_message}</td>
                  <td>
                    {task.name === 'code_open_ends_threading' ? (
                      <Button
                        intent='danger'
                        disabled={task.status !== 'PENDING' && task.status !== 'IN_PROGRESS'}
                        onClick={() => handleCancel(task.id)}
                      >
                        Cancel
                      </Button>
                    ) : null}
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={7}>Loading...</td>
            </tr>
          )}
        </tbody>
      </HTMLTable>
      <Alert
        isOpen={cancelAlertOpen}
        loading={false}
        cancelButtonText="Cancel"
        confirmButtonText="Cancel Task"
        onConfirm={() => cancelTask(cancelTaskId)}
        onCancel={() => setCancelAlertOpen(false)}
        cancel
        intent="danger"
      >
        THIS IS A DESTRUCTIVE ACTION. ARE YOU SURE YOU WANT TO CANCEL THIS TASK?
      </Alert>
    </div>
  )
}
